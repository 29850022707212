<template>
	<div id="menu">
		<SelectorIndexed v-model="sportId" :options="sports" @input="update"></SelectorIndexed>
		<menu-item :sport="sport" :sportId="sportId"></menu-item>
		<div class="menu-item">
			<h1>
				<font-awesome-icon :icon="['fas', 'cogs']"/>
				Other
			</h1>
			<div class="sub-menu-item" ref="sub-menu-item" id="other">
				<router-link to="/other/seasons">Seasons</router-link>
				<router-link to="/other/settings">Settings</router-link>
				<router-link to="/login" v-if="!user.loggedIn">Login</router-link>
				<router-link to="/logout" v-else>Logout</router-link>
			</div>
		</div>
	</div>
</template>

<script>
import MenuItem from '@/components/menu/MenuItem'
import SelectorIndexed from "@/components/general/SelectorIndexed";

export default {
	name: "AppMenu",
	props: ['sports'],
	components: {
		SelectorIndexed,
		MenuItem
	},
	data: function () {
		return {
			sport: {},
			sportId: 1,
		}
	},
	methods: {
		update() {
			let parts = this.$route.path.slice(1).split("/")
			if (parts[0]) {
				// Don't redirect to self
				if(parts[0] !== (this.sports.find(x => x.id === this.sportId).name.toLowerCase() || parts[0])) {
					parts[0] = this.sports.find(x => x.id === this.sportId).name || parts[0]
					this.sport = this.sports.find(x => x.name === parts[0])
					this.sportId = this.sport.id
					this.$router.push(("/" + parts.join("/")).toLowerCase())
				}
			}
		},
		getSport() {
			let parts = this.$route.path.slice(1).split("/")
			let sport = parts[0] || "football"
			if (parts[0]) {
				if(parts[0] === "login") {
					parts = this.$route.path.slice(1).split("%2f")
					sport = parts[1] || "football"
				}
				//console.log("inner", sport)
				return this.sports.find(x => x.name.toLowerCase() === sport.toLowerCase()) || this.sports[0];
			}
			//console.log("outer")
			return this.sports[0]
		},
		clicker() {
			if (!this.$refs['sub-menu-item'].classList.contains('hide')) {
				// Toggle
				this.$refs['sub-menu-item'].classList.add('hide');
			} else {
				// Show only chosen sub-menu
				document.querySelectorAll('.sub-menu-item').forEach(function (item) {
					item.classList.add('hide');
				});
				this.$refs['sub-menu-item'].classList.remove('hide');

				// Set active menu-item
				document.querySelectorAll('.menu-item').forEach(function (item) {
					item.classList.remove('active');
				});
				this.$refs['sub-menu-item'].parentElement.classList.add('active')
			}
		},
		setSport() {
			this.sport = this.getSport()
			this.sportId = this.sport.id
			// console.log("SPORT", this.sport, "SPORT_ID", this.sportId)
		}
	},
	async mounted() {
		this.$router.onReady(() => {
			this.setSport()
		})
	},

}

</script>

<style scoped lang="scss">
div#menu::v-deep {
	display: block;
	border-right: 1px solid #333;
	width: 300px;
	padding: 0px 20px 20px 20px;
	background-color: #000;
	height: calc(100vh - 83px);
	float: left;
	box-sizing: border-box;
	overflow-y: auto;
	overflow-x: hidden;
	margin-right: 10px;

	> div {
		background-color: #000;
		color: white;
		padding: 6px 10px;

		> h1 {
			> svg {
				width: 24px !important;
			}
		}
	}


	div.menu-item {
		&:last-child {
			margin-top: 10px;
			border-top: 1px dotted #666;
		}

		> h1 {
			color: gold;
			font-size: 1.4em;
			margin: 0;
		}

		> h1:hover {
			color: #fff0b0; /* light gold */
		}

		> div.sub-menu-item {
			> div {
				> div {
					padding-left: 6px;
				}
			}
		}
	}

	a,
	a:visited,
	a:active,
	a:hover {
		display: block;
		text-decoration: none;
		color: #ccc;
		margin-left: 32px;
	}

	a:active,
	a.router-link-active {
		color: aqua;
	}

	a:hover {
		cursor: pointer;
		color: #fff;
	}
}

</style>
