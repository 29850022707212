<template>
	<div class="menu-item">
		<h1>
			<font-awesome-icon v-if="sport.id===1" :icon="['fas', 'futbol']"/>
			<font-awesome-icon v-if="sport.id===15" :icon="['fas', 'baseball-ball']"/>
			{{sport.name}}
		</h1>
		<div class="sub-menu-item" ref="sub-menu-item" v-if="sportId===1">
			<router-link to="/football/coaches">Coaches</router-link>
			<router-link to="/football/competitors">Competitors</router-link>
			<router-link to="/football/contest-groups">Contest groups</router-link>
			<router-link to="/football/matches">Matches</router-link>
			<router-link to="/football/officials">Officials</router-link>
			<router-link to="/football/players">Players</router-link>
			<router-link to="/football/tournaments">Tournaments</router-link>
			<router-link to="/football/venues">Venues</router-link>
		</div>
		<div class="sub-menu-item" ref="sub-menu-item" v-if="sportId===15">
			<router-link to="/cricket/coaches">Coaches</router-link>
			<router-link to="/cricket/competitors">Competitors</router-link>
			<router-link to="/cricket/contest-groups">Contest groups</router-link>
			<router-link to="/cricket/matches">Matches</router-link>
			<router-link to="/cricket/officials">Officials</router-link>
			<router-link to="/cricket/players">Players</router-link>
			<router-link to="/cricket/tournaments">Tournaments</router-link>
			<router-link to="/cricket/venues">Venues</router-link>
		</div>
	</div>
</template>

<script>

export default {
	name: "MenuItem",
	props: ['sport','sportId'],
	components: {},
	methods: {
		clicker: function () {
			if (!this.$refs['sub-menu-item'].classList.contains('hide')) {
				// Toggle
				this.$refs['sub-menu-item'].classList.add('hide');
			} else {
				// Show only chosen sub-menu
				document.querySelectorAll('.sub-menu-item').forEach(function (item) {
					item.classList.add('hide');
				});
				this.$refs['sub-menu-item'].classList.remove('hide');

				// Set active menu-item
				document.querySelectorAll('.menu-item').forEach(function (item) {
					item.classList.remove('active');
				});
				this.$refs['sub-menu-item'].parentElement.classList.add('active')
			}
		}
	},
}

</script>

<style scoped lang="scss">

</style>
