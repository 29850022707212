<template>
	<div>
		<font-awesome-icon :icon="['fas', 'volleyball-ball']"/>
		{{ name }} -> {{ path }}
	</div>
</template>

<script>

export default {
	name: "AppHeader",
	data: function () {
		return {
			name: '',
			path: '',
		}
	},
	mounted() {
		this.$router.onReady(() => {
			console.log("AppHeader", this.$route.name, this.$route.path)
			this.name = this.$route.name
			this.path = this.$route.path
		})
	}
}
</script>

<style scoped lang="scss">
div {
	border-bottom: 2px solid #000;
	height: 80px;
	line-height: 80px;
	text-align: center;
	margin-top: auto;
	margin-bottom: auto;
	font-size: 1.5em;
	background-color: #000;
	color: #fff;
}
</style>
