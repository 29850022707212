import Vue from "vue";
import helpers from "@/helpers";

export const globals = new Vue({
    data: {
        providers: ["manual", "enet", "betway", "Soccerway", "AiScore"],
        messages: {saving: "You are already saving, please wait for it to complete<br>If nothing happens, try refreshing the page"},
        user: {
            loggedIn: false,
            settings: {
                debug: true,
                showId: false,
                numResults: 30,
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                showLegend: true,
            },
            permissions: []
        },
        seasons: [],
        countries: [],
        loading: true,
    },
    async created() {
        this.loading = true
        await Promise.all([
            this.getSeasons(),
            this.getCountries(),
            this.getUserData(),
        ]);
        this.loading = false
        await this.$eventHub.$emit("globalsLoaded", {})
    },
    methods: {
        getUserData: async function () {
            console.log("getUserData")
            let loggedIn = false
            let response = await helpers.users.getCurrent().then(
                async response => {
                    if (response.ok) {
                        response = await response.json()
                        if (response.enabled) {
                            loggedIn = true
                        }
                        return response
                    } else if (response.status === 401) {
                        console.log("getUserData -> login redirect")
                    }
                    return false
                }).catch(
                (error) => {
                    loggedIn = false
                    console.log("getUserData fail")
                    console.error('Error:', error);
                });
            if (!response) {
                this.user.loggedIn = false
                return this.user
            }
            response.settings = JSON.parse(response.settings || "{}")

            // adding defaults
            response.settings = {
                ...this.user.settings,
                ...response.settings
            }
            this.user = response
            this.user.loggedIn = loggedIn
            this.loading = false;
            return this.user // or response?
        },
        getSeasons: async function () {
            console.log("getSeasons")
            const response = await helpers.seasons.getAll({sort: ["name,desc"]}).then(
                response => {
                    if (response.ok) {
                        return response.json()
                    } else if (response.status === 401) {
                        console.log("getSeasons -> login redirect")
                    }
                }).catch(
                (error) => {
                    console.log("getSeasons fail -> login redirect")
                    console.error('Error:', error);
                });
            if (!response) {
                this.user.loggedIn = false
                return []
            }
            this.seasons = response.content
            return response.content
        },
        getCountries: async function () {
            console.log("getCountries")
            const response = await helpers.countries.getAll({size: 500, sort: ["name,asc"]}).then(
                response => {
                    if (response.ok) {
                        return response.json()
                    } else if (response.status === 401) {
                        console.log("getCountries -> login redirect")
                    }
                }).catch(
                (error) => {
                    console.log("getCountries fail -> login redirect")
                    console.error('Error:', error);
                });
            if (!response) {
                this.user.loggedIn = false
                return []
            }
            this.countries = response.content
            return response.content
        },
    }
})
