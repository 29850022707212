<template>
	<v-app>
		<AppHeader :key="$route.fullPath + $route.params"></AppHeader>
		<div>
			<AppMenu :key="$route.fullPath + $route.params" :sports="sports"></AppMenu>
			<v-main class="app-main" transition="slide-x-transition">
				<router-view :key="$route.fullPath + $route.params" :sports="sports" ></router-view>
			</v-main>
		</div>
	</v-app>
</template>

<script>
import Vue from 'vue'
import AppMenu from './components/menu/AppMenu'
import AppHeader from './components/AppHeader'
import helpers from "./helpers";
import {globals} from "@/globals";

Vue.mixin({
	data: function () {
		return {
			countries: [],
			seasons: [],
			user: {settings:{}},
		}
	},
	mounted() {
		//console.log("App mount")
		if (!globals.loading) {
			this.countries = globals.countries
			this.seasons = globals.seasons
			this.user = globals.user
		} else {
			this.$eventHub.$on('globalsLoaded', () => {
				this.countries = globals.countries
				this.seasons = globals.seasons
				this.user = globals.user
			})
		}
	},
	methods: {
		// focus first element in form
		tabOver: function () {
			this.$el.querySelector('input, select, textarea, button').focus()
		},
		// get age from birth date
		getAge: function (birthday) {
			return helpers.getAge(birthday)
		},
		footballMatchStatusToScoreType: function (matchStatus) {
			if (matchStatus === "1st half" || matchStatus === "Half time") {
				return "Half time";
			}
			if (matchStatus === "2nd half" || matchStatus === "Full time") {
				return "Full time";
			}
			if (matchStatus === "ET 1st half" || matchStatus === "1st extra time") {
				return "1st extra time";
			}
			if (matchStatus === "ET 2nd half" || matchStatus === "2nd extra time") {
				return "2nd extra time";
			}
			if (matchStatus === "Finished AET" || matchStatus === "After extra time") {
				return "After extra time";
			}
			if (matchStatus === "Finished AP" || matchStatus === "After penalties") {
				return "After penalties";
			}
			if (matchStatus === "Penalties") {
				return "Penalties";
			}
			return 'Half time';
		},
	}
});

export default {
	name: 'App',
	components: {
		AppMenu, AppHeader
	},
	data: function () {
		return {
			sports: [
				{
					"id": 1,
					"name": "Football"
				},
				{
					"id": 15,
					"name": "Cricket"
				},
			],
		}
	},
	async mounted() {
		// make sure this.$route is ready
		await this.$route
		await this.openMenuAtRoute()
	},
	methods: {
		openMenuAtRoute: async function () {
			// make sure this.$route is ready
			await this.$route
			// get the first token from the url
			let path = this.$route.path.slice(1);
			let id = path.slice(0, path.indexOf('/'))
			if (globals.user.settings.debug) console.log("open menu at: ", id)
			if (document.getElementById(id)) {
				document.getElementById(id).classList.remove('hide')
			}
		},
	}
}
</script>

<style>
.app-main {
	display: block;
	float: left;
	width: calc(100% - 311px);
	height: calc(100vh - 82px);
	overflow-y: auto;
}

</style>
