import Vue from 'vue'
import App from './App.vue'
import {globals} from './globals'
import router from './routes/router'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import VueToast from "vue-toast-notification";
import 'vue-toast-notification/dist/theme-sugar.css';
import {library} from '@fortawesome/fontawesome-svg-core'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import VueLuxon from "vue-luxon";
import EventHub from 'vue-event-hub';
import {fas} from "@fortawesome/free-solid-svg-icons";
//library.add(fab)
library.add(fas)
//library.add(far)
Vue.config.silent = true
Vue.use(VueLuxon, {
    input: {
        zone: "utc",
        format: "iso"
    },
    output: {
        zone: "local",
        format: {
            year: "numeric",
            month: "2-digit",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            hourCycle: "h23",
        },

        locale: null,
        relative: {
            round: true,
            unit: null
        }
    }
});
//import VueTextareaAutosize from "vue-textarea-autosize";
//Vue.use(VueTextareaAutosize);

Vue.config.productionTip = false;
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.config.productionTip = false
Vue.use(Vuetify)
Vue.use(VueToast, {duration: 10000})
Vue.use(EventHub);
//dummy for loading event hub
const tmp = new Vue({EventHub})
if (globals.loading) {
    tmp.$eventHub.$on('globalsLoaded', () => {
        init()
    })
} else {
    init()
}

function init() {
    window.vm = new Vue({
        render: h => h(App),
        globals,
        router,
        vuetify: new Vuetify({
            theme: {
                dark: true,
                disable: true
            },
        })
    }).$mount('#app')
}
