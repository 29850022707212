






import {Component, Watch, Prop, Vue} from 'vue-property-decorator';

@Component({
	components: {}
})
export default class SelectorIndexed extends Vue {
	@Prop() options: Array<any>
	@Prop() value: number

	//Apparently a watcher is import to get the default value set
	get selected() {
		return this.value
	}

	set selected(val) {
		this.$emit('input', val)
	}

	@Watch('selected')
	onValueChange(val: number) {
		this.selected = val
	}
}
