import {
    FootballCard,
    FootballCoach, FootballCoachRole,
    FootballCompetitor,
    FootballContestGroup,
    FootballContestGroupParticipant,
    FootballContestRound,
    FootballContestRoundsBatch,
    FootballGoal,
    FootballLineupPlayer,
    FootballMatch,
    FootballOfficial,
    FootballPlayer,
    FootballScoreType,
    FootballSubstitution,
    FootballTournament,
    FootballTransfer,
    FootballVenue, PatchObject,
    Season,
    Sport, User
} from "./types";

const base_url = "https://test.gorkha.dk:8080/"

// Get resource(s)
async function fetcherGet(endpoint: string, data: object | any = {}) {
    data = data || {}
    const urlParams = new URLSearchParams(data)
    const url = base_url + endpoint + '?' + urlParams.toString()
    console.log("GET from:", url)
    return fetch(url, {
        method: "GET",
        mode: "cors",
        credentials: 'include',
        headers: {
            "Content-Type": "application/json"
        },
    })
}

// Update resource, full
async function fetcherPost(endpoint: string, data: object) {
    console.log("POST", data)
    return fetch(base_url + endpoint, {
        method: "POST",
        mode: "cors",
        credentials: 'include',
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
    });
}

// Update resource, partial
async function fetcherPut(endpoint: string, data: object) {
    return fetch(base_url + endpoint, {
        method: "PUT",
        mode: "cors",
        credentials: 'include',
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
    });
}

// Update resource, partial
async function fetcherPatch(endpoint: string, data: object) {
    return fetch(base_url + endpoint, {
        method: "PATCH",
        mode: "cors",
        credentials: 'include',
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
    });
}

// Delete resource
async function fetcherDelete(endpoint: string, id: number) {
    return fetch(base_url + endpoint + '/' + id, {
        method: "DELETE",
        mode: "cors",
        credentials: 'include',
        headers: {
            "Content-Type": "application/json"
        }
    })
}

export default {
    users: {
        getAll: async function (data: object) {
            return fetcherGet('users', data)
        },
        getCurrent: async function () {
            return fetcherGet('users/current', {})
        },
        updateSettings: async function (user: User, settings: object) {
            user.settings = JSON.stringify(settings)
            return fetcherPut('users', user)
        },
        update: async function (user: User) {
            return fetcherPut('users', user)
        },
    },
    enums: {
        getAll: async function (data: object) {
            return fetcherGet('enums', data)
        },
    },
    sports: {
        delete: function (id: number) {
            return fetcherDelete('sports', id);
        },
        update: async function (sport: Sport) {
            // @ts-ignore
            delete (sport.lastUpdate)
            return fetcherPut('sports', sport)
        },
        create: function (sport: Sport) {
            return fetcherPost('sports', sport)
        },
        getAll: async function (data: object) {
            return fetcherGet('sports', data)
        },
    },
    countries: {
        getAll: async function (data: object) {
            return fetcherGet('countries', data)
        }
    },
    seasons: {
        getAll: async function (data: object) {
            return fetcherGet('seasons', data)
        },
        create: async function (season: Season) {
            // @ts-ignore
            delete (season.lastUpdate)
            return fetcherPost('seasons', season)
        },
        update: async function (season: Season) {
            // @ts-ignore
            delete (season.lastUpdate)
            return fetcherPut('seasons', season)
        },
        delete: function (id: number) {
            return fetcherDelete('seasons', id);
        },
    },
    football: {
        contestgroups: {
            delete: function (id: number) {
                return fetcherDelete('football/contest-groups', id);
            },
            update: function (contestGroup: FootballContestGroup) {
                // @ts-ignore
                delete (contestGroup.lastUpdate)
                return fetcherPut('football/contest-groups', contestGroup)
            },
            create: function (contestGroup: FootballContestGroup) {
                // @ts-ignore
                delete (contestGroup.lastUpdate)
                return fetcherPost('football/contest-groups', contestGroup)
            },
            getAll: async function (data: object) {
                return fetcherGet('football/contest-groups', data)
            },
            get: async function (id: number) {
                return fetcherGet('football/contest-groups/' + id as string)
            },
        },
        contestgroupcompetitors: {
            delete: function (id: number) {
                return fetcherDelete('football/contest-groups-competitors', id);
            },
            update: function (contestGroup: FootballContestGroupParticipant) {
                // @ts-ignore
                delete (contestGroup.lastUpdate)
                return fetcherPut('football/contest-groups-competitors', contestGroup)
            },
            create: function (contestGroup: FootballContestGroupParticipant) {
                // @ts-ignore
                delete (contestGroup.lastUpdate)
                return fetcherPost('football/contest-groups-competitors', contestGroup)
            },
            getAll: async function (data: object) {
                return fetcherGet('football/contest-groups-competitors', data)
            },
            get: async function (id: number) {
                return fetcherGet('football/contest-groups-competitors/' + id as string)
            },
        },
        contestgroupparticipants: {
            delete: function (id: number) {
                return fetcherDelete('football/contest-group-participants', id);
            },
            update: function (contestGroupParticipant: FootballContestGroupParticipant) {
                // @ts-ignore
                delete (contestGroupParticipant.lastUpdate)
                return fetcherPut('football/contest-group-participants', contestGroupParticipant)
            },
            create: function (contestGroupParticipant: FootballContestGroupParticipant) {
                // @ts-ignore
                delete (contestGroupParticipant.lastUpdate)
                return fetcherPost('football/contest-group-participants', contestGroupParticipant)
            },
            getAll: async function (data: object) {
                return fetcherGet('football/contest-group-participants', data)
            },
            get: async function (id: number) {
                return fetcherGet('football/contest-group-participants/' + id as string)
            },
        },

        contestrounds: {
            // getAll not available on this endpoint
            delete: function (id: number) {
                return fetcherDelete('football/contest-rounds', id);
            },

            update: function (contestRound: FootballContestRound) {
                // @ts-ignore
                delete (contestRound.lastUpdate)
                return fetcherPut('football/contest-rounds', contestRound)
            },

            create: function (contestRound: FootballContestRound) {
                // @ts-ignore
                delete (contestRound.lastUpdate)
                return fetcherPost('football/contest-rounds', contestRound)
            },
            get: async function (id: number) {
                return fetcherGet('football/contest-rounds/' + id as string)
            },
            //contestGroupId required
            getAll: async function (data: object) {
                return fetcherGet('football/contest-rounds/', data)
            },
        },
        tournaments: {
            getAll: async function (data: object) {
                return fetcherGet('football/tournaments', data)
            },
            get: async function (id: number) {
                return fetcherGet('football/tournaments/' + id as string)
            },
            delete: function (id: number) {
                return fetcherDelete('football/tournaments', id);
            },
            create: function (tournament: FootballTournament) {
                // @ts-ignore
                delete (tournament.lastUpdate)
                return fetcherPost('football/tournaments', tournament)
            },
            update: function (tournament: FootballTournament) {
                // @ts-ignore
                delete (tournament.lastUpdate)
                return fetcherPut('football/tournaments', tournament)
            },
        },
        transfers: {
            delete: function (id: number) {
                return fetcherDelete('football/transfers', id);
            },
            getAll: async function (data: object) {
                return fetcherGet('football/transfers', data)
            },
            get: async function (id: number) {
                return fetcherGet('football/transfers/' + id as string)
            },
            create: function (transfer: FootballTransfer) {
                // @ts-ignore
                delete (transfer.lastUpdate)
                return fetcherPost('football/transfers', transfer)
            },
            update: function (transfer: FootballTransfer) {
                // @ts-ignore
                delete (transfer.lastUpdate)
                return fetcherPut('football/transfers', transfer)
            }
        },
        players: {
            delete: async function (id: number) {
                return fetcherDelete('football/players', id);
            },
            update: async function (player: FootballPlayer) {
                // @ts-ignore
                delete (player.lastUpdate)
                return fetcherPut('football/players', player)
            },
            create: async function (player: FootballPlayer) {
                // @ts-ignore
                delete (player.lastUpdate)
                return fetcherPost('football/players', player)
            },
            getAll: async function (data: object) {
                return fetcherGet('football/players', data)
            },
            get: async function (id: number) {
                return fetcherGet('football/players/' + id as string)
            },
            patch: async function (id: number, patchObj: PatchObject) {
                return fetcherPatch('football/players/' + id as string, patchObj)
            }
        },
        lineupplayers: {
            getAll: async function (data: object) {
                return fetcherGet('football/lineup-players', data)
            },
            get: async function (id: number) {
                return fetcherGet('football/lineup-players/' + id as string)
            },
            delete: function (id: number) {
                return fetcherDelete('football/lineup-players', id);
            },
            create: function (lineupPlayer: FootballLineupPlayer) {
                // @ts-ignore
                delete (lineupPlayer.lastUpdate)
                return fetcherPost('football/lineup-players', lineupPlayer)
            },
            update: async function (lineupPlayer: FootballLineupPlayer) {
                // @ts-ignore
                delete (lineupPlayer.lastUpdate)
                return fetcherPut('football/lineup-players', lineupPlayer)
            },

        },
        coaches: {
            delete: function (id: number) {
                return fetcherDelete('football/coaches', id);
            },
            update: async function (coach: FootballCoach) {
                // @ts-ignore
                delete (coach.lastUpdate)
                return fetcherPut('football/coaches', coach)
            },
            create: function (coach: FootballCoach) {
                // @ts-ignore
                delete (coach.lastUpdate)
                return fetcherPost('football/coaches', coach)
            },
            getAll: async function (data: object) {
                return fetcherGet('football/coaches', data)
            },
            get: async function (id: number) {
                return fetcherGet('football/coaches/' + id as string)
            },
        },
        coachroles: {
            delete: function (id: number) {
                return fetcherDelete('football/coach-roles', id);
            },
            update: async function (role: FootballCoachRole) {
                // @ts-ignore
                delete (role.lastUpdate)
                delete (role.coach)
                return fetcherPut('football/coach-roles', role)
            },
            create: function (role: FootballCoachRole) {
                // @ts-ignore
                delete (role.lastUpdate)
                delete (role.coach)
                return fetcherPost('football/coach-roles', role)
            },
            getAll: async function (data: object) {
                return fetcherGet('football/coach-roles', data)
            },
            get: async function (id: number) {
                return fetcherGet('football/coach-roles/' + id as string)
            },
        },
        competitors: {
            delete: function (id: number) {
                return fetcherDelete('football/competitors', id);
            },
            update: async function (competitor: FootballCompetitor) {
                // @ts-ignore
                delete (competitor.lastUpdate)
                return fetcherPut('football/competitors', competitor)
            },
            create: function (competitor: FootballCompetitor) {
                // @ts-ignore
                delete (competitor.lastUpdate)
                return fetcherPost('football/competitors', competitor)
            },
            getAll: async function (data: object) {
                return fetcherGet('football/competitors', data)
            },
            get: async function (id: number) {
                return fetcherGet('football/competitors/' + id as string)
            },
        },
        matches: {
            getAll: async function (data: object) {
                return fetcherGet('football/matches', data)
            },
            get: async function (id: number) {
                return fetcherGet('football/matches/' + id as string)
            },
            delete: function (id: number) {
                return fetcherDelete('football/matches', id);
            },
            create: function (match: FootballMatch) {
                // @ts-ignore
                delete (match.lastUpdate)
                return fetcherPost('football/matches', match)
            },
            update: async function (match: FootballMatch) {
                // @ts-ignore
                delete (match.lastUpdate)
                return fetcherPut('football/matches', match)
            },
            patch: async function (id: number, match: object) {
                return fetcherPatch('football/matches/' + id, match)
            },
        },
        matchcompetitors: {},
        cards: {
            delete: function (id: number) {
                return "Prohibited! Set the type to deleted " + id
                //return fetcherDelete('football/cards', id);
            },
            create: function (card: FootballCard) {
                return fetcherPost('football/cards', card)
            },
            update: async function (card: FootballCard) {
                return fetcherPut('football/cards', card)
            },
            getAll: async function (data: object) {
                return fetcherGet('football/cards', data)
            },
            get: async function (id: number) {
                return fetcherGet('football/cards/' + id as string)
            },
        },
        goals: {
            delete: function (id: number) {
                return fetcherDelete('football/goals', id);
            },
            update: async function (goal: FootballGoal) {
                return fetcherPut('football/goals', goal)
            },
            create: function (goal: FootballGoal) {
                return fetcherPost('football/goals', goal)
            },
            getAll: async function (data: object) {
                return fetcherGet('football/goals', data)
            },
            get: async function (id: number) {
                return fetcherGet('football/goals/' + id as string)
            },
        },
        officials: {
            delete: function (id: number) {
                return fetcherDelete('football/officials', id);
            },
            getAll: async function (data: object) {
                return fetcherGet('football/officials', data)
            },
            get: async function (id: number) {
                return fetcherGet('football/officials/' + id as string)
            },
            create: function (official: FootballOfficial) {
                return fetcherPost('football/officials', official)
            },
            update: function (official: FootballOfficial) {
                return fetcherPut('football/officials', official)
            },
        },
        scores: {
            create: async function (matchId: number, home: number, away: number, type: FootballScoreType) {
                if (!type) {
                    throw new Error('Type not supplied')
                }
                return fetcherPost("football/scores", {matchId, home, away, type: type})
            },
            update: async function (id: number, matchId: number, home: number, away: number, type: FootballScoreType) {
                return fetcherPut("football/scores", {id, matchId, home, away, type: type})
            }
        },
        substitutions: {
            delete: function (id: number) {
                return fetcherDelete('football/substitutions', id);
            },
            update: async function (substitution: FootballSubstitution) {
                return fetcherPut('football/substitutions', substitution)
            },
            create: function (substitution: FootballSubstitution) {
                return fetcherPost('football/substitutions', substitution)
            },
            getAll: async function (data: object) {
                return fetcherGet('football/substitutions', data)
            },
            get: async function (id: number) {
                return fetcherGet('football/substitutions/' + id as string)
            },
        },
        venues: {
            delete: function (id: number) {
                return fetcherDelete('football/venues', id);
            },
            update: async function (venue: FootballVenue) {
                return fetcherPut('football/venues', venue)
            },
            create: function (venue: FootballVenue) {
                return fetcherPost('football/venues', venue)
            },
            getAll: async function (data: object) {
                return fetcherGet('football/venues', data)
            },
            get: async function (id: number) {
                return fetcherGet('football/venues/' + id as string)
            },
        },
        actions: {
            createcontestrounds: {
                create: function (contestRoundsBatch: FootballContestRoundsBatch) {
                    return fetcherPost('football/actions/create-contest-rounds', contestRoundsBatch)
                },
            }
        }
    },
    focused: function (element: Element) {
        return (document.activeElement === element)
    },
    getAge: function (birthday: Date) {
        const today = new Date();
        let thisYear = 0;
        if (today.getUTCMonth() < birthday.getUTCMonth()) {
            thisYear = 1;
        } else if ((today.getUTCMonth() === birthday.getUTCMonth()) && today.getUTCDate() < birthday.getUTCDate()) {
            thisYear = 1;
        }
        return today.getUTCFullYear() - birthday.getUTCFullYear() - thisYear;
    },
    dateTimeFormat: function (date: string, format: string) {
        const d = new Date(date)
        if (!format.length) {
            format = "Y-m-d";
        }
        let result = ""
        for (let i = 0; i < format.length; i++) {
            switch (format[i]) {
                case 'd':
                    result += d.getUTCDate().toString().padStart(2, '0')
                    break;
                case 'm':
                    result += (d.getUTCMonth() + 1).toString().padStart(2, '0')
                    break;
                case 'Y':
                    result += d.getUTCFullYear().toString()
                    break;
                case 'H':
                    result += d.getUTCHours().toString().padStart(2, '0')
                    break;
                case 'i':
                    result += d.getUTCMinutes().toString().padStart(2, '0')
                    break;
                case 's':
                    result += d.getUTCSeconds().toString().padStart(2, '0')
                    break;
                default:
                    result += format[i]
            }
        }
        return result
    },
    removeObjectFromArray: function (arr: [], id: number) {
        return arr.filter((data: { id: number }) => data.id != id);
    },
    // member methods will be discarded and references broken - but you will get a deep clone
    clone: function (obj: object) {
        return JSON.parse(JSON.stringify(obj))
    }
}
