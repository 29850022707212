import Vue from 'vue'
import VueRouter, {NavigationGuardNext, Route} from 'vue-router'
import {globals} from "@/globals"

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    // @ts-ignore
    routes: [
        {
            path: '/',
            alias: '/home',
            name: 'home',
            component: () => import('../views/Home.vue')
        },
        {
            path: '/other/settings',
            name: 'settings',
            component: () => import('../views/Settings.vue')
        },
        // cricket
        {
            name: "cricket",
            path: "/cricket/",
            component: () => import('../views/cricket/Cricket.vue'),
            children: [
                {
                    path: 'coaches',
                    name: 'cricket-coaches-list',
                    component: () => import('../views/cricket/CoachesListView.vue')
                },
                {
                    path: 'competitors',
                    name: 'cricket-competitors-list',
                    component: () => import('../views/cricket/CompetitorsListView.vue')
                },
                {
                    path: 'contest-groups',
                    name: 'cricket-contest-groups-list',
                    component: () => import('../views/cricket/ContestGroupsListView.vue')
                },
                {
                    path: 'matches',
                    name: 'cricket-matches-list',
                    component: () => import('../views/cricket/MatchesListView.vue')
                },
                {
                    path: 'officials',
                    name: 'cricket-officials-list',
                    component: () => import('../views/cricket/OfficialsListView.vue')
                },
                {
                    path: 'players',
                    name: 'cricket-players-list',
                    component: () => import('../views/cricket/PlayersListView.vue')
                },
                {
                    path: 'test',
                    name: 'cricket-test',
                    component: () => import('../views/cricket/TestView.vue')
                },
                {
                    path: 'tournaments',
                    name: 'cricket-tournaments-list',
                    component: () => import('../views/cricket/TournamentsListView.vue')
                },
                {
                    path: 'venues',
                    name: 'cricket-venues-list',
                    component: () => import('../views/cricket/VenuesListView.vue')
                },
            ]
        },
        // Football
        {
            name: "football",
            path: '/football/',
            component: () => import('../views/football/Football.vue'),
            children: [
                {
                    path: 'coaches',
                    name: 'football-coaches',
                    component: () => import('../views/football/CoachesListView.vue')
                },
                {
                    path: 'coaches-create',
                    name: 'football-coaches-create',
                    component: () => import('../views/football/CoachesCreate.vue')
                },
                {
                    path: 'coaches-edit/:id',
                    name: 'football-coaches-edit',
                    component: () => import('../views/football/CoachesCreate.vue')
                },
                {
                    path: 'contest-groups',
                    name: 'football-contest-groups',
                    component: () => import('../views/football/ContestGroupsListView.vue')
                },
                {
                    path: 'contest-groups-create',
                    name: 'football-contest-groups-create',
                    component: () => import('../views/football/ContestGroupsCreate.vue')
                },
                {
                    path: 'contest-groups-edit/:id',
                    name: 'football-contest-groups-edit',
                    component: () => import('../views/football/ContestGroupsCreate.vue')
                },
                {
                    path: 'contest-rounds',
                    name: 'football-contest-rounds',
                    component: () => import('../views/football/ContestRounds.vue')
                },
                {
                    path: 'contest-rounds/:id',
                    name: 'football-contest-rounds-id',
                    component: () => import('../views/football/ContestRounds.vue')
                },
                {
                    path: 'contest-rounds-create',
                    name: 'football-contest-rounds-create',
                    component: () => import('../views/football/ContestRoundsCreate.vue')
                },
                {
                    path: 'contest-rounds-create/:id',
                    name: 'football-contest-rounds-create-id',
                    component: () => import('../views/football/ContestRoundsCreate.vue')
                },
                {
                    path: 'contest-rounds-edit/:id',
                    name: 'football-contest-rounds-edit',
                    component: () => import('../views/football/ContestRoundsCreate.vue')
                },
                {
                    path: 'matches',
                    name: 'football-matches',
                    component: () => import('../views/football/MatchesListView.vue')
                },
                {
                    path: 'matches-create',
                    name: 'football-matches-create',
                    component: () => import('../views/football/MatchesCreate.vue')
                },
                {
                    path: 'matches-edit/:id',
                    name: 'football-matches-edit',
                    component: () => import('../views/football/MatchesCreate.vue')
                },
                {
                    path: 'competitors',
                    name: 'football-competitors',
                    component: () => import('../views/football/CompetitorsListView.vue')
                },
                {
                    path: 'competitors-create',
                    name: 'football-competitors-create',
                    component: () => import('../views/football/CompetitorsCreate.vue')
                },
                {
                    path: 'competitors-edit/:id',
                    name: 'football-competitors-edit',
                    component: () => import('../views/football/CompetitorsCreate.vue')
                },
                {
                    path: 'competitor-players/:id',
                    name: 'football-competitor-players',
                    component: () => import('../views/football/CompetitorPlayers.vue')
                },
                {
                    path: 'players',
                    name: 'football-players',
                    component: () => import('../views/football/PlayersListView.vue')
                },
                {
                    path: 'players-create',
                    name: 'football-players-create',
                    component: () => import('../views/football/PlayersCreate.vue')
                },
                {
                    path: 'players-edit/:id',
                    name: 'football-players-edit',
                    component: () => import('../views/football/PlayersCreate.vue')
                },
                {
                    path: 'venues',
                    name: 'football-venues',
                    component: () => import('../views/football/VenuesListView.vue')
                },
                {
                    path: 'venues-create',
                    name: 'football-venues-create',
                    component: () => import('../views/football/VenuesCreate.vue')
                },
                {
                    path: 'venues-edit/:id',
                    name: 'football-venues-edit',
                    component: () => import('../views/football/VenuesCreate.vue')
                },
                {
                    path: 'tournaments',
                    name: 'football-tournaments',
                    component: () => import('../views/football/TournamentsListView.vue')
                },
                {
                    path: 'tournaments-create',
                    name: 'football-tournaments-create',
                    component: () => import('../views/football/TournamentsCreate.vue'),
                    children: [
                        {
                            path: 'test',
                            component: () => import('../views/football/Transfers.vue')
                        }
                    ]
                },
                {
                    path: 'tournaments-edit/:id',
                    name: 'football-tournaments-edit',
                    component: () => import('../views/football/TournamentsCreate.vue')
                },
                {
                    path: 'transfers',
                    name: 'football-transfers',
                    component: () => import('../views/football/Transfers.vue')
                },
                {
                    path: 'transfers-create',
                    name: 'football-transfers-create',
                    component: () => import('../views/football/TransfersCreate.vue')
                },
                {
                    path: 'transfers-edit/:id',
                    name: 'football-transfers-edit',
                    component: () => import('../views/football/TransfersCreate.vue')
                },
                {
                    path: 'substitutions',
                    name: 'football-substitutions',
                    component: () => import('../views/football/Substitutions.vue')
                },
                {
                    path: 'substitutions-create',
                    name: 'football-substitutions-create',
                    component: () => import('../views/football/SubstitutionsCreate.vue')
                },
                {
                    path: 'substitutions-edit/:id',
                    name: 'football-substitutions-edit',
                    component: () => import('../views/football/SubstitutionsCreate.vue')
                },
                {
                    path: 'goals',
                    name: 'football-goals',
                    component: () => import('../views/football/Goals.vue')
                },
                {
                    path: 'goals-create',
                    name: 'football-goals-create',
                    component: () => import('../views/football/GoalsCreate.vue')
                },
                {
                    path: 'lineup-players',
                    name: 'football-lineup-players',
                    component: () => import('../views/football/LineupPlayers.vue')
                },
                {
                    path: 'lineup-players-create',
                    name: 'football-lineup-players-create',
                    component: () => import('../views/football/LineupPlayersCreate.vue')
                },
                {
                    path: 'lineup-players-edit/:id',
                    name: 'football-lineup-players-edit',
                    component: () => import('../views/football/LineupPlayersCreate.vue')
                },
                {
                    path: 'officials',
                    name: 'football-officials',
                    component: () => import('../views/football/OfficialsListView.vue')
                },
                {
                    path: 'officials-create',
                    name: 'football-officials-create',
                    component: () => import('../views/football/OfficialsCreate.vue')
                },
                {
                    path: 'officials-edit/:id',
                    name: 'football-officials-edit',
                    component: () => import('../views/football/OfficialsCreate.vue'),
                }
            ]
        },
        // Seasons
        {
            path: '/other/seasons',
            alias: '/other/season',
            name: 'seasons',
            component: () => import('../views/seasons/Edit.vue'),
        },
        //login
        {
            path: '/login',
            name: 'login',
            component: () => import('../views/Login.vue'),
        },
        //logout
        {
            path: '/logout',
            name: 'logout',
            component: () => import('../views/Logout.vue'),
        },
        //default
        {
            path: '/*',
            name: 'default',
            component: () => import ('../views/404.vue')
        }
    ]
});
router.beforeEach((to: Route, from: Route, next: NavigationGuardNext<Vue>) => {
    if (to.name !== 'login' && to.name !== 'test' && !globals.user.loggedIn) {
        console.log("ET:", to.path)
        next({
            path: 'login',
            replace: true,
            query: {redirect: to.path}
        })
    } else {
        next();
    }
})
export default router
